@use "@/styles/variables.scss";

.termsAcceptedContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: .5rem;
}

.termsAcceptedCheckbox {
  width: 1rem;
  height: 1rem;
}

.termsAcceptedText {
  color: blue;
  text-decoration: underline;
  cursor: pointer;
}

.hidden {
  display: none;
}

.loginContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.loginForm {
  width: 420px;
  margin: 21px auto;
  background-color: variables.$color-content;
  border: 1px solid variables.$color-border;
}

.loginLabel {
  margin-bottom: 30px;
  font-size: 22px;
  font-weight: variables.$fontweight-bold;
}

.signupLabel {
  font-family: variables.$font-title;
  font-size: variables.$fontsize-button;
  font-weight: variables.$fontweight-medium;
  color: variables.$color-readable-dark;
  text-transform: uppercase;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 30px;
  line-height: 1.3;
}

.formActions {
  width: 100%;
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

button.userTypeToggle {
  width: 100%;
}

.submitButton {
  margin-top: 20px;
  margin-bottom: 10px;
  width: 100%;

  button {
    width: 100%;
  }
}

.backButton {
  background-color: white;
  color: hsl(213deg 46% 34%);
  margin-top: 15px;
}

.terms {
  font-size: variables.$fontsize-normal;
}
.termsLink {
  text-decoration: underline;
  font-size: variables.$fontsize-normal;

  &:hover {
    color: #fb7124;
  }
}

.passwordInput {
  margin-bottom: 7px !important;
}

.rightButtonLink {
  display: inline-block;
  margin-left: 5px;
}

.forgotContainer {
  justify-content: flex-end;
}

@media screen and (max-width: variables.$screen-sm-max) {
  .loginForm {
    padding: 24px;
  }
}

@media screen and (min-width: variables.$screen-md-min) and (max-width: variables.$screen-lg-max) {
  .loginForm {
    padding: 30px;
  }
}

@media screen and (min-width: variables.$screen-xl-min) {
  .loginForm {
    padding: 34px;
  }
}

.error {
  color: variables.$color-danger;
}