.hidden {
  display: none;
}

.readerOuterContainer {
  width: 50vw;
}

.readerInnerContainer {
  margin: auto;
  width: 45vw;
}

@media (max-width: 667px) {
  .readerOuterContainer {
    width: 95vw;
  }
  .readerInnerContainer {
    width: 90vw;
  }
}

.button:first-child {
  margin-top: 20px;
}

.button {
  background-color: white;
  color: hsl(213deg 46% 34%);
  margin: 20px 25%;
  width: 50%;
}
