@use "@/styles/variables.scss";

.webviewer {
  height: 75vh;
  width: 100%;

  &:global(.invisible) {
    height: 1px;
  }
}

.loadingSpinner {
  height: 75px;
  width: 75px;
  font-size: 20px;
}

.editorSpinnerLoading {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  height: 75vh;
  margin-left: auto;
  margin-right: auto;
}

.noFileMessage {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  height: 75vh;
  margin-left: auto;
  margin-right: auto;
}

.break {
  width: 100%;
  height: 0;
}

.editorSpinnerReady {
  display: none;
}

.errorLabel {
  background-color: variables.$color-ch-yellow;
}

.zoomButton {
  display: span;
  border-style: solid;
  font-size: 20px;
  height: 30px;
  width: 30px;
  text-align: center;
  align-items: middle;
  margin-top: auto;
  margin-bottom: auto;
  font-weight: 600;
  border-width: 0px;
}

.pdfViewerHeaderViewOnly {
  display: flex;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: #f1f3f5;
}

.zoomOverlay {
  margin-left: 20px;
  padding-left: 10px;
  border-left: 1px solid #cfd4da;
}

.readonlyViewerContainer {
  display: flex;
  flex-direction: column;
}

.readonlyViewer {
  letter-spacing: 0px;
  flex-grow: 1;
  overflow: auto;
  height: 100%;
}

.readonlyViewerToolbar {
  align-items: center;
  background-color: #eeeeee;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  padding: 0.25rem;
  overflow-x: auto;
  overflow-y: hidden;
}