@use "@/styles/variables.scss";

.errorContainer {
  display: flex;
  flex-direction: column;
  max-width: 25rem;
  border: 1px solid lightgray;
  background-color: white;
  border-radius: 10px;
  padding: 4rem;
}

.errorMessageContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.errorCode {
  color: red;
  margin: 1rem 0 1rem 1rem;
  padding: 0 0.5rem 0 0.5rem;
  font-size: 3rem;
}

.errorMessage {
  margin: 1rem;
  font-style: italic;
}

.errorDetails {
  text-align: center;
  font-size: 1.2rem;
  margin: 0.25rem 0 2.5rem 0;
}

.errorLogoContainer {
  text-align: center;
}

.logoContainer {
  position: relative;
  width: 80%;
  height: 66px;
  margin-top: 10px;
  margin-bottom: 20px;

  img {
    object-fit: contain;
    user-select: none;
    outline: none !important;
  }
}

.loginHeaderContainer {
  margin-left: 50px;
  margin-right: 50px;
  margin-top: 100px;
}

.loginHeader {
  color: variables.$color-readable-light;
  margin-bottom: 50px;
}

.loginImage {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 80%;
  margin-top: 50px;
}

.loginColumn {
  background-color: variables.$color-sidebar;
  color: white;
}

.leftColumn {
  background-color: variables.$color-background;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.loginLabel {
  margin-bottom: 20px;
  margin-top: 100px;
}

.submitButton {
  margin-top: 20px;
  margin-bottom: 10px;
  width: 100%;

  button {
    width: 100%;
  }
}

.formContainer {
  min-width: 80%;
}

.loginForm {
  min-width: 300px;
  width: 60%;
  margin: 0px auto;
}

.backButton {
  background-color: white;
  color: hsl(213deg 46% 34%);
  margin-top: 15px;
}

.buttonLink {
  color: white;
  margin-top: 15px;
  &:hover {
    color: #007bff;
  }
}

.leftButtonLink {
  @extend .buttonLink;
  float: left;
}

.rightButtonLink {
  @extend .buttonLink;
  float: right;
}

.hidden {
  display: none;
}

button.userTypeToggle {
  width: 100%;
}

.label {
  color: #fff !important;
}

.inputContainer {
  margin-bottom: 21px;
}

.sessionExpiredContainer {
  border: 1px outset black;
  margin: 20px 0;
  padding: 10px;
  background-color: variables.$color-orange;
  color: #fff;
}

.sessionExpiredText {
  margin: auto;
}

.copyright {
  position: absolute;
  bottom: 14px;
  text-align: center;
  width: 100%;
}

@media screen and (min-width: variables.$screen-sm-min) and (max-width: variables.$screen-sm-max) {
  .loginHeader {
    font-size: 24px;
  }
}
